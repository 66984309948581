import { useEffect } from "react";

const useScrollWhileOnDropdown = (
  isOpen: boolean,
  setIsOpen: (open: boolean) => void
) => {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsOpen(false);
    });
    window.addEventListener("wheel", () => {
      setIsOpen(false);
    });
    window.addEventListener("touchmove", () => {
      setIsOpen(false);
    });

    return () => {
      window.removeEventListener("scroll", () => {
        setIsOpen(false);
      });
      window.removeEventListener("wheel", () => {
        setIsOpen(false);
      });
      window.removeEventListener("touchmove", () => {
        setIsOpen(false);
      });
    };
  }, [isOpen]);
};

export default useScrollWhileOnDropdown;
